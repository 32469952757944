<template>
  <div :class="[$style.sidebar, { [$style.active]: isOpen }]">
    <div :class="$style.hide" @click="$emit('toggle', !isOpen)">
      <Icon
        name="arrow"
        :class="[$style.arrow, { [$style.reverse]: isOpen }]"
        fill
      />
    </div>
    <div :class="$style.inner">
      <div :class="$style.header">
        <div :class="$style.information">
          Заметки дилера:
          <span :class="$style.name">{{ dealerName }}</span>
        </div>
        <Icon
          name="close"
          :class="$style.close"
          @click="$emit('toggle', false)"
        />
      </div>
      <div :class="$style.content">
        <div>
          <div v-if="notes?.length" :class="$style.notes">
            <NoteCard
              v-for="note in notes"
              :key="note?.id"
              :dealerId="note?.dealers?.[0]?.id"
              :parentId="note?.id"
              :name="note?.managerAccount?.name"
              :role="note?.managerAccount?.role"
              :image="note?.managerAccount?.image"
              :created-at="note?.createdAt"
              :note-message="note?.message"
              :comments="note?.comments"
              @new-note="createNote"
              @delete="deleteNote"
              @edit="editNote"
            />
            <el-button
              v-if="notes?.length && total > notes?.length"
              @click="loadMoreNotes"
              type="primary"
              >Загрузить ещё</el-button
            >
          </div>
          <div v-else :class="$style.empty">
            Здесь будут находится ваши заметки о данном дилере
          </div>
        </div>
      </div>
      <div :class="$style.bottom">
        <div v-if="isShowInput" :class="$style.inputWrapper">
          <el-input
            v-model="message"
            :disabled="isLoading"
            clearable
            placeholder="Введите вашу заметку"
            @keyup.enter.native="message && createNote({ newNote: message })"
          />
          <Icon
            name="telegramArrow"
            :class="$style.icon"
            @click.prevent="
              !isLoading && message && createNote({ newNote: message })
            "
          />
        </div>
        <div v-else :class="$style.buttonWrapper">
          <button :class="$style.button" @click="isShowInput = true">
            Создать заметку
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Icon from '@/components/atoms/Icon.vue'
import NoteCard from '@/components/atoms/cards/NoteCard.vue'
import delivery from '@/delivery'
import notifications from '@/mixins/notifications'
export default {
  components: {
    Icon,
    NoteCard,
  },
  mixins: [notifications],
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    dealerId: {
      type: String,
      default: '',
    },
    dealerName: {
      type: String,
      default: '',
    },
  },
  async mounted() {
    this.dealerId && (await this.getNotesList())
  },
  data() {
    return {
      page: 1,
      limit: 10,
      total: 0,
      notes: [],
      message: '',
      isLoading: false,
      isShowInput: false,
    }
  },
  watch: {
    async dealerId() {
      this.page = 1
      this.isShowInput = false
      await this.getNotesList()
    },
  },
  methods: {
    async createNote({ parentId, newNote }) {
      this.isShowInput = false
      this.isLoading = true
      this.message = ''
      const { error } =
        await delivery.AddwineCore.DealersActions.createDealerNote(
          this.dealerId,
          {
            parentId,
            message: newNote,
          },
        )
      this.isLoading = false
      if (error) {
        this.showNotification('Ошибка создания заметки', 'error')
        return
      }
      if (parentId) {
        await this.updateNoteById(parentId)
        return
      }
      this.page = 1
      this.showNotification('Заметка успешно создана', 'success')
      await this.getNotesList()
    },
    async editNote({ parentId, noteId, message }) {
      this.isLoading = true
      const { error } = await delivery.AddwineCore.NotesActions.update(noteId, {
        parentId,
        message,
      })
      this.isLoading = false
      if (error) {
        this.showNotification('Ошибка обновления заметки', 'error')
        return
      }
      await this.updateNoteById(parentId, noteId)
    },
    async deleteNote({ id, parentId }) {
      this.isLoading = true
      const { error } = await delivery.AddwineCore.NotesActions.delete(id)
      this.isLoading = false
      if (error) return
      if (!parentId) {
        await this.getLastNote()
        this.notes = this.notes.filter((note) => note?.id != id)
        return
      }
      await this.updateNoteById(parentId, id)
    },
    async getNotesList() {
      this.isLoading = true
      const { value, error } =
        await delivery.AddwineCore.DealersActions.getNotesList(this.dealerId, {
          page: this.page,
          limit: this.limit,
          preloadDealers: true,
        })
      this.isLoading = false
      if (error) {
        this.showNotification('Ошибка получения заметок', 'error')
        return
      }
      this.notes = value.data
      this.total = value.meta.count
    },
    async updateNoteById(parentId, noteId) {
      this.isLoading = true
      const { value, error } = await delivery.AddwineCore.NotesActions.getById(
        parentId ?? noteId,
      )
      this.isLoading = false
      if (error) return
      this.notes = this.notes.map((note) => {
        if (note?.id === value?.id) return value
        return note
      })
    },
    async getLastNote() {
      this.isLoading = true
      const { value, error } =
        await delivery.AddwineCore.DealersActions.getNotesList(this.dealerId, {
          page: this.page * this.limit,
          limit: 1,
          preloadDealers: true,
        })
      this.isLoading = false
      if (error) return
      this.total = value.meta.count
      value.data.length && this.notes.push(value.data[0])
    },
    async loadMoreNotes() {
      this.isLoading = true
      this.page += 1
      const { value, error } =
        await delivery.AddwineCore.DealersActions.getNotesList(this.dealerId, {
          page: this.page,
          limit: this.limit,
        })
      this.isLoading = false
      if (error) return
      this.notes = [...this.notes, ...value.data]
    },
  },
}
</script>

<style lang="scss" module>
.sidebar {
  position: fixed;
  width: 31.5rem;
  right: 0;
  top: 0;
  height: 100%;
  transform: translate(100%);
  z-index: $z-index-notes-sidebar;
  transition: 0.3s transform ease-in-out;
  background: $cotton-ball;

  &.active {
    transform: translateX(0%);
    box-shadow: 0.125rem 0.125rem 0.5rem 0 $benthic-black;
  }

  .hide {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    background: $white;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    box-shadow: 0.25rem 0.25rem 1rem 0 $benthic-black;
    .arrow {
      color: $bright-gold;
      fill: currentColor;
      width: 0.5rem;
      transition: 0.3s transform ease-in-out;
      &.reverse {
        transform: scaleX(-1);
      }
    }
  }
  .inner {
    height: 100%;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    &::-webkit-scrollbar {
      width: 0;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background: transparent;
    }
    .header {
      position: sticky;
      top: 0;
      padding: 1rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background: $white;
      box-shadow: 0.25rem 0.25rem 1rem 0 $benthic-black;

      .information {
        @include noteDealerName(true);
        color: $grey-russian;
        .name {
          color: $tabel-th-color;
        }
      }
      .close {
        cursor: pointer;
      }
    }
    .content {
      flex-grow: 1;
      padding: 1.5rem 1rem 0 1rem;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .notes {
        display: flex;
        flex-direction: column;
        row-gap: 1rem;
      }
      .empty {
        max-width: 18.75rem;
        margin: 0 auto;
        @include noteDealerName;
        text-align: center;
      }
    }
    .bottom {
      position: sticky;
      bottom: 0;
      background: $cotton-ball;
      .inputWrapper {
        background: $white;
        padding: 0.5rem 1.5rem 0.5rem 1rem;
        display: flex;
        gap: 1rem;
        align-items: center;
        .icon {
          flex-shrink: 0;
          cursor: pointer;
        }
      }
      .buttonWrapper {
        padding: 1rem 1rem 1rem;
        .button {
          width: 100%;
          padding: 0.7rem;
          background: $note-dropdown-header;
          border: none;
          color: $white;
          border-radius: 0.5rem;
          cursor: pointer;
          @include noteDealerName(true);
        }
      }
    }
  }
}
</style>
