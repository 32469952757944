<template>
  <div :class="$style.card">
    <div :class="$style.cardWrapper">
      <div :class="$style.mainInfoWrapper">
        <div :class="$style.mainInfo">
          <div :class="$style.image">
            <img
              :src="$configData.s3_link + image"
              :alt="image"
              @error="setDefaultImage"
            />
          </div>
          <div :class="$style.nameWrapper">
            <span :class="$style.name">{{ name }}</span>
            <span :class="$style.role">{{ role }}</span>
          </div>
        </div>
        <span :class="$style.time"
          >{{ formatDate(createdAt) }} | {{ formatTime(createdAt) }}</span
        >
      </div>
      <div :class="$style.note">
        <el-input
          v-if="parentId === editId"
          v-model="editNoteValue"
          autosize
          type="textarea"
          resize="none"
          @keyup.enter.native="editNote(null, parentId)"
        >
        </el-input>
        <span v-else :class="$style.message">{{ noteMessage }}</span>
        <div v-if="isAdminOrOwner" :class="$style.actions">
          <Icon
            name="eye"
            :class="$style.icon"
            @click="
              $router.push(
                getRoute({
                  route: $options.ADDSELLERS_ADMIN_ROUTES.DEALERS.EDIT,
                  params: { id: dealerId },
                }),
              )
            "
          />
          <Icon
            v-if="parentId === editId"
            name="save"
            :class="$style.icon"
            @click="editNote(null, parentId)"
          />
          <Icon
            v-else
            name="edit"
            :class="$style.icon"
            @click="setEditNode(parentId, noteMessage)"
          />
          <Icon
            name="trash"
            :class="$style.icon"
            @click="deleteNote(parentId)"
          />
        </div>
      </div>
    </div>
    <el-collapse @change="isOpen = !isOpen" :class="$style.dropdown">
      <el-collapse-item :class="$style.collapseItem">
        <template slot="title">
          <span :class="$style.titleText">{{
            !isOpen ? commentsCountText : ''
          }}</span>
        </template>
        <div :class="$style.comments">
          <div
            v-for="note in comments"
            :key="note?.id"
            :class="[$style.cardWrapper, $style.comment]"
          >
            <div :class="$style.mainInfoWrapper">
              <div :class="$style.mainInfo">
                <div :class="$style.image">
                  <img
                    :src="$configData.s3_link + note?.managerAccount?.image"
                    :alt="note?.managerAccount?.image"
                    @error="setDefaultImage"
                  />
                </div>
                <div :class="$style.content">
                  <div :class="$style.header">
                    <span :class="$style.name">{{
                      note?.managerAccount?.name
                    }}</span>
                    <span :class="$style.time"
                      >{{ formatDate(note?.createdAt) }} |
                      {{ formatTime(note?.createdAt) }}
                    </span>
                  </div>
                  <div :class="$style.note">
                    <el-input
                      v-if="note?.id === editId"
                      v-model="editNoteValue"
                      autosize
                      type="textarea"
                      resize="none"
                      @keyup.enter.native="
                        editNoteValue && editNote(note?.parentId, note?.id)
                      "
                    >
                    </el-input>
                    <span v-else :class="$style.message">{{
                      note?.message
                    }}</span>
                    <div v-if="isAdminOrOwner" :class="$style.actions">
                      <Icon
                        v-if="note?.id === editId"
                        name="save"
                        :class="$style.icon"
                        @click="editNote(note?.parentId, note?.id)"
                      />
                      <Icon
                        v-else
                        name="edit"
                        :class="$style.icon"
                        @click="setEditNode(note?.id, note?.message)"
                      />
                      <Icon
                        name="trash"
                        :class="$style.icon"
                        @click="deleteNote(note?.id, parentId)"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div :class="$style.inputWrapper">
          <el-input
            v-model="newNote"
            clearable
            placeholder="Введите вашу заметку"
            @keyup.enter.native="createNote"
          >
          </el-input>
          <Icon
            name="telegramArrow"
            :class="$style.icon"
            @click.prevent="createNote"
          />
        </div>
      </el-collapse-item>
    </el-collapse>
  </div>
</template>
<script>
import Icon from '@/components/atoms/Icon.vue'
import { getStringEnding } from '@/helpers/index'
import { formatDate, formatTime } from '@/helpers/index.js'
import images from '@/mixins/images'
import { mapGetters } from 'vuex'
import { ADDSELLERS_ADMIN_ROUTES, getRoute } from '@/constants/routing'

export default {
  ADDSELLERS_ADMIN_ROUTES,
  mixins: [images],
  components: { Icon },
  props: {
    image: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    parentId: {
      type: String,
      default: '',
    },
    dealerId: {
      type: String,
      default: '',
    },
    role: {
      type: String,
      default: '',
    },
    createdAt: {
      type: String,
      default: '',
    },
    noteMessage: {
      type: String,
      default: '',
    },
    comments: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      isOpen: false,
      newNote: '',
      editId: '',
      editNoteValue: '',
    }
  },
  computed: {
    commentsCountText() {
      const commentsEndings = ['комментарий', 'комментария', 'комментариев']

      return `${this.comments?.length} ${this.getStringEnding(
        this.comments?.length,
        commentsEndings,
      )}`
    },
    ...mapGetters({
      isAdminOrOwner: 'isAdminOrOwner',
    }),
  },
  methods: {
    createNote() {
      this.$emit('new-note', {
        parentId: this.parentId,
        newNote: this.newNote,
        dealerId: this.dealerId,
      })
      this.newNote = ''
    },
    editNote(parentId, noteId) {
      this.$emit('edit', {
        parentId,
        noteId,
        message: this.editNoteValue,
      })
      this.editNoteValue = ''
      this.editId = ''
    },
    deleteNote(id, parentId) {
      const html = document.querySelector('html')
      html.style.overflowY = 'hidden'
      this.$confirm('Вы действительно хотите удалить заметку?', 'Внимание', {
        confirmButtonText: 'Подтвердить',
        cancelButtonText: 'Отменить',
        type: 'warning',
      }).then(() => {
        this.$emit('delete', { id, parentId })
        html.style.overflowY = 'auto'
      })
    },
    setEditNode(id, message) {
      this.editId = id
      this.editNoteValue = message
    },
    formatDate(date) {
      return formatDate(date)
    },
    formatTime(date) {
      return formatTime(date)
    },
    getStringEnding(number, endsList) {
      return getStringEnding(number, endsList)
    },
    getRoute({ route, params, site }) {
      return getRoute({ route, params, site })
    },
  },
}
</script>
<style>
.el-collapse-item__header {
  height: 2.5rem;
  border: none;
}
.el-collapse-item__content {
  padding: 0;
  line-height: normal;
}
.el-collapse-item__wrap {
  border: none;
}

.el-popup-parent--hidden {
  overflow: initial;
}
</style>
<style lang="scss" module>
.card {
  max-width: 29.5rem;
  background-color: $white;
  padding: 0.5rem;
  border-radius: 0.5rem;
  .cardWrapper {
    flex: 1 1 100%;
    display: flex;
    flex-direction: column;
    gap: 0.625rem;
    padding-bottom: 1rem;
    &.comment {
      padding: 0;
    }
    .mainInfoWrapper {
      flex: 1 1 100%;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      gap: 1rem;

      .mainInfo {
        display: flex;
        align-items: flex-start;
        gap: 0.5rem;
        .image {
          img {
            width: 3rem;
            height: 3rem;
            object-fit: cover;
            border-radius: 50%;
          }
        }

        .nameWrapper {
          display: flex;
          flex-direction: column;

          .name {
            @include text;
            color: $black;
          }

          .role {
            @include text;
            color: $gray;
          }
        }
      }

      .time {
        @include text;
        color: $gray;
      }
    }

    .note {
      display: flex;
      justify-content: space-between;
      gap: 1rem;
      .message {
        word-wrap: break-word;
        @include text;
        color: $black;
      }
      .actions {
        display: flex;

        gap: 0.5rem;
        .icon {
          display: flex;
          width: 1rem;
          height: 1rem;
        }
      }
    }
  }

  .comments {
    display: grid;
    gap: 1rem;
    margin-bottom: 1.625rem;
    .cardWrapper,
    .mainInfoWrapper {
      .mainInfo {
        flex: 1 1 100%;

        .content {
          flex: 1 1 100%;
          .header {
            display: flex;
            justify-content: space-between;
            gap: 1rem;
          }
        }
      }
    }
  }

  .inputWrapper {
    display: flex;
    gap: 1rem;
    align-items: center;
    border-radius: 0.5rem;

    .icon {
      cursor: pointer;
    }
  }

  .dropdown {
    .collapseItem {
      & > div:last-child {
      }

      & > div:first-child > div {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .titleText {
          @include noteDropdownText;
          color: $note-dropdown-header;
        }

        i {
          font-size: 1rem;
          color: $note-dropdown-header;
          margin: 0;
        }
      }
    }
  }
}
</style>
